'use client'

import React from 'react'
import { usePathname } from 'next/navigation'

const canonicalBaseUrl = 'https://www.rene-kathofer.at'

export const CanonicalLink = () => {
  const pathname = usePathname()
  const canonicalUrl = `${canonicalBaseUrl}${pathname}`
  return <link rel="canonical" href={canonicalUrl} />
}