'use client'

import React, { useEffect, useState } from 'react'

interface ToggleSwitchProps {
  isEnabled: boolean;
  onToggle: (checked: boolean) => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ isEnabled, onToggle }) => {
  const [isMounted, setIsMounted] = useState(false)

  const handleCheckboxChange = () => {
    onToggle(!isEnabled)
  }

  useEffect(() => {
    // Set the component as mounted to trigger animations
    const timeout = setTimeout(() => {
      setIsMounted(true)
    }, 50) // Add slight delay for a smooth initial effect

    return () => clearTimeout(timeout)
  }, [])

  return (
    <div
      onClick={handleCheckboxChange}
      className="relative flex h-7 w-14 cursor-pointer items-center justify-between rounded-full bg-gray-300 p-1 transition dark:bg-gray-700"
    >
      {/* Toggle circle with mount-aware animation */}
      <div
        className={`bg-white ${
          isMounted ? 'w-5 h-5' : 'w-full h-full'
        } rounded-full transition-all duration-300 ease-in-out ${
          isMounted
            ? isEnabled
              ? 'translate-x-7'
              : 'translate-x-0'
            : ''
        }`}
      />
    </div>
  )
}

export default ToggleSwitch