'use client'

import React, { useEffect, useState } from 'react'
import Logo from '@/components/Logo'
import { FaBars, FaXmark } from 'react-icons/fa6'
import Link from 'next/link'
import DarkModeToggle from '@/components/DarkModeToggle'

const NAV_LINKS = [
  { href: '/#main', label: 'Über mich' },
  { href: '/#services', label: 'Meine Services' },
  { href: '/#projects', label: 'Projekte' },
  { href: '/#knowledge', label: 'Kenntnisse' },
]

function NavLinks({ className = '', onClick, activeSection }: {
  className?: string;
  onClick?: () => void;
  activeSection?: string | null
}) {
  return (
    <>
      {NAV_LINKS.map((link) => (
        <Link
          key={link.href}
          href={link.href}
          className={`font-medium  tracking-wide  text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200 ${className} ${
            activeSection === link.href ? 'text-gray-800 dark:text-gray-200 underline' : ''
          }`}
          onClick={onClick}
        >
          {link.label}
        </Link>
      ))}
    </>
  )
}

export function Header() {
  const [menuOpen, setMenuOpen] = useState(false)
  const [activeSection, setActiveSection] = useState<string | null>(null)

  const toggleMenu = () => setMenuOpen((prev) => !prev)
  const closeMenu = () => setMenuOpen(false)

  useEffect(() => {
    const handleScroll = () => {
      const sections = NAV_LINKS.map((link) => document.querySelector(link.href.replace('/#', '#')))
      let currentSection: string | null = null

      sections.forEach((section, index) => {
        if (section && section.getBoundingClientRect().top <= window.innerHeight / 2) {
          currentSection = NAV_LINKS[index].href
        }
      })

      setActiveSection(currentSection)
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <header className="sticky top-0 z-10 bg-white dark:bg-gray-900">
      {/* Desktop and Mobile Navbar */}
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
        <div>
          <Link href="/">
            <Logo />
          </Link>
        </div>
        {/* Desktop Navbar Links */}
        <div className="hidden sm:space-x-8 sm:ml-10 sm:flex sm:items-center sm:pr-4">
          <NavLinks activeSection={activeSection} />
          <DarkModeToggle />
        </div>
        {/* Mobile Hamburger Menu */}
        <div className="flex items-center sm:hidden">
          <button
            aria-label="Toggle menu"
            className="text-gray-500 focus:outline-none dark:text-gray-400"
            onClick={toggleMenu}
          >
            <FaBars size={24} />
          </button>
        </div>
      </nav>
      {/* Mobile Sidebar Menu */}
      <div
        className={`fixed top-0 right-0 h-full w-1/2 bg-white sm:hidden dark:bg-gray-900 shadow-md transform ${
          menuOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out z-20`}
      >
        <div className="flex items-end justify-end p-4">
          <button
            aria-label="Close menu"
            className="text-gray-500 focus:outline-none dark:text-gray-400"
            onClick={closeMenu}
          >
            <FaXmark size={24} />
          </button>
        </div>
        <div className="flex flex-col items-end p-4 space-y-4">
          <NavLinks activeSection={activeSection} onClick={closeMenu} />
          <div>
            <DarkModeToggle />
          </div>
        </div>
      </div>
      {menuOpen && (
        <div
          className="fixed inset-0 z-10 bg-black bg-opacity-50 sm:hidden"
          onClick={closeMenu}
        ></div>
      )}
    </header>
  )
}