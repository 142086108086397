'use client'

import React, { useEffect, useState } from 'react'
import Image from 'next/image'

const getRandomNumber = (min: number, max: number): number =>
  Math.random() * (max - min) + min

const INITIAL_CLIP_PATH = 'polygon(4% 6%, 98% 2%, 97% 98%, 2% 96%)'

const CLIP_PATH_UPDATE_INTERVAL = 3000

const generatePolygonPoints = (): string => {
  const points = [
    `${getRandomNumber(0, 10)}% ${getRandomNumber(0, 10)}%`,
    `${getRandomNumber(90, 100)}% ${getRandomNumber(0, 10)}%`,
    `${getRandomNumber(90, 100)}% ${getRandomNumber(90, 100)}%`,
    `${getRandomNumber(0, 10)}% ${getRandomNumber(90, 100)}%`,
  ]
  return `polygon(${points.join(', ')})`
}

interface BeveledImageProps {
  src: string;
  alt: string;
  className?: string;
}

const BeveledImage: React.FC<BeveledImageProps> = ({ src, alt, className }) => {
  const [clipPath, setClipPath] = useState<string>(INITIAL_CLIP_PATH)

  useEffect(() => {
    const updateClipPath = () => setClipPath(generatePolygonPoints())
    updateClipPath()
    const intervalId = setInterval(updateClipPath, CLIP_PATH_UPDATE_INTERVAL)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <div
      className={className}
      style={{
        clipPath,
        transition: 'clip-path 3s ease-in-out',
      }}
    >
      <Image
        src={src}
        alt={alt}
        className="h-full w-full object-contain"
        width={0}
        height={0}
        sizes="100vw"
        priority={true} />
    </div>
  )
}

export default BeveledImage