'use client'

import React from 'react'
import { useTheme } from '@/context/ThemeProvider'
import ToggleSwitch from '@/components/ToggleSwitch'

const DarkModeToggle = () => {
  const { theme, toggleTheme } = useTheme()
  return <ToggleSwitch isEnabled={theme === 'dark'} onToggle={toggleTheme} />
}

export default DarkModeToggle